import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    children:[
      {
        path: '/home/student',
        name: 'student',
        component: () => import('../views/StudentView.vue'),
      },
      {
        path: '/home/detail',
        name: 'detail',
        component: () => import('../views/DetailView.vue'),
      },
      {
        path: '/home/zhunbei',
        name: 'zhunbei',
        component: () => import('../views/ZhunbeiView.vue'),
      },
      {
        path: '/home/teacher',
        name: 'teacher',
        component: () => import('../views/TeacherView.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  let islogin = sessionStorage.getItem('hqc_user')
  console.log(to.name);
  if(to.name == 'login'){
    next()
  }else{
    if(islogin!=''){
      next()
    }else{
      next('/')
    }
  }

  
})

export default router
